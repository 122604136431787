<template>
  <b-row class="match-height">
    <b-col cols="12" v-if="can('show/manajemen-nonpegawai')">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">No NIK KTP</h6>
            <p class="card-text mb-25">
              {{ model.nik }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Nama</h6>
            <p class="card-text mb-25">
              {{ model.name }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Tempat, Tanggal Lahir</h6>
            <p class="card-text mb-25">
              {{ model.pob || '-' }},
              {{ model.dob | moment('DD/MM/YYYY') || '-' }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">NPWP</h6>
            <p class="card-text mb-25">
              {{ model.npwp || '-' }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">No Rekening</h6>
            <p class="card-text mb-25">
              {{ model.no_rek || '-' }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Kantor Asal</h6>
            <p class="card-text mb-25">
              {{ model.office }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Jenis Non Pegawai</h6>
            <p class="card-text mb-25">
              {{ model.type_non_employee && model.type_non_employee.name }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Jabatan</h6>
            <p class="card-text mb-25">
              {{ model.position || '-' }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Level Kantor</h6>
            <p class="card-text mb-25">
              {{ levelName || '-' }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Bagian/Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ model.bagian || '-' }}
            </p>
          </b-col>
        </b-row>
        <b-card-footer>
          <!-- back -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'manajemen-nonpegawai' }"
          >
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Forbidden Permission</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import { BRow, BCol, BButton, BCard, BCardFooter } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/non-employees',
        level: '/misc/levels',
        redirect: 'manajemen-nonpegawai',
      },
      model: {},
      confirmPass: null,
      roles: [],
      statusList: [
        {
          label: 'Aktif',
          value: 'active',
        },
        {
          label: 'Tidak Aktif',
          value: 'inactive',
        },
      ],
      messages: '',
      levels: [
        { id: 0, name: 'KANTOR PUSAT' },
        { id: 1, name: 'REGIONAL' },
        { id: 2, name: 'KCU/KC/MPC/SGLK' },
        { id: 3, name: 'KCP/DC/LE' },
        { id: 4, name: 'AGENPOS' },
      ],
    }
  },
  computed: {
    levelName() {
      const level = this.levels.filter(({ id }) => id == this.model.level)[0]

      return level?.name
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios
        .get(`${this.config.api}/${this.$route.params.id}`)
        .then(response => {
          this.model = response.data.data
        })
    },
  },
}
</script>
